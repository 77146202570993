<template>
  <div>
    <v-dialog v-model="dialogOpen"
      v-if="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      max-width="600"
      @keydown.esc="close"
      style="height: 50px">
      <confirm ref="confirmStorno"
        @selection="cancelReceipt" />
      <v-card tile
        color="white">
        <v-card-title style="
            height: 80px;
            padding-top: 0px;
            position: sticky;
            border-bottom: 1px solid lightgrey;
          ">
          <v-col cols="5">
            <v-row>
              <h3 class="text--secondary">
                {{ $t("$vuetify.cashRegister.receiptsOverview") }}
              </h3>
            </v-row>
            <v-row>
              <h5>
                Od:
                {{
                  new Date(last_balance_timestamp * 1000).toLocaleString(
                    "hr-HR"
                  )
                }}
              </h5>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-text-field class="ma-0"
              style="position: relative; top: 0.8rem; width: 180px"
              outlined
              dense
              clearable
              data-layout="numericAduro"
              v-model="searchNumber"
              :label="$t('$vuetify.cashRegister.searchByReceiptNumber')"
              @click:prepend="doSearch"
              @click:clear="doSearch"
              @click:append="doSearch"
              @focus="showKbd"
              @click="showKbd"
              @keydown.enter="doSearch"
              @keypress.enter.prevent="doSearch"></v-text-field>
          </v-col>
          <v-col cols="2"><v-btn @click="doSearch"><v-icon>mdi-magnify</v-icon></v-btn></v-col>
        </v-card-title>
        <v-card-text style="padding: 0; margin: 0; overflow-x: hidden; height: 600px">
          <v-row>
            <v-col cols="12"
              style="min-width: 100%">
              <v-expansion-panels focusable>
                <v-expansion-panel v-for="(receipt, i) in receipts"
                  :key="i"
                  hover
                  tabindex="0"
                  style="border-radius: 0">
                  <v-expansion-panel-header height="200"
                    :class="[
                      receipt.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                        receipt.type.flag !== 'P' &&
                        receipt.fiscalize === true &&
                        receipt.zki !== '' &&
                        receipt.jir === '' &&
                        issueBefore(receipt.issue_date_time) > now
                        ? 'status-failed-exp'
                        : 'status-ok-exp',
                      receipt.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                        receipt.type.flag !== 'P' &&
                        receipt.fiscalize === true &&
                        receipt.zki !== '' &&
                        receipt.jir === '' &&
                        issueBefore(receipt.issue_date_time) < now
                        ? 'status-failed-expired'
                        : 'status-ok-exp',
                      receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                        receipt.type.flag !== 'P' &&
                        parseInt(receipt.payment_due) > now &&
                        receipt.payment !== undefined &&
                        receipt.payment.current_status !== 'PAID'
                        ? 'status-warn-exp'
                        : '',
                      receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                        receipt.type.flag !== 'P' &&
                        parseInt(receipt.payment_due) < now &&
                        receipt.payment !== undefined &&
                        receipt.payment.current_status !== 'PAID'
                        ? 'status-warn-after-exp'
                        : '',
                      receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                        receipt.type.flag !== 'P' &&
                        receipt.payment !== undefined &&
                        receipt.payment.current_status === 'PAID'
                        ? 'status-ok-exp'
                        : '',
                      receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                        receipt.type.flag === 'P'
                        ? 'status-ok-exp'
                        : '',
                      receipt.payment !== undefined &&
                        receipt.payment.current_status === 'REVERSED'
                        ? 'status-reversed'
                        : '',
                      receipt.type.flag === 'STORNO' ? 'status-storno' : '',
                    ]"
                    hide-actions>
                    <v-row>
                      <v-col cols="3">
                        <div class="caption grey--text text-center">
                          {{
                            reciptOffer === "receipts"
                              ? $t("$vuetify.receipts.receiptNumber")
                              : $t("$vuetify.receipts.offerNumber")
                          }}
                        </div>
                        <div class="text-center">
                          {{ receipt.receiptNumber }}
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <div class="caption grey--text text-right">
                          {{
                            reciptOffer === "receipts"
                              ? $t("$vuetify.receipts.receiptPrice")
                              : $t("$vuetify.receipts.offerPrice")
                          }}
                        </div>
                        <div class="text-right">
                          {{ receipt.total | money(100, receipt.currency) }}
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="caption grey--text text-center">
                          {{ $t("$vuetify.receipts.receiptIssueDate") }}
                        </div>
                        <div class="text-center">
                          {{ receipt.issue_date_time | local }}
                        </div>
                      </v-col>
                      <v-col cols="2"
                        class="d-flex justify-end"
                        style="margin-top: 2px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                              v-bind="attrs"
                              v-on="on"
                              @keypress.enter.stop="">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item v-for="(item, index) in receipt.receiptOptions"
                              :key="index"
                              style="cursor: pointer"
                              @click="`${item.action(receipt)}`"
                              @keypress.enter.prevent="
                                `${item.action(receipt)}`
                                "
                              :disabled="receipt.payment &&
                                receipt.payment.current_status === 'PROCESSING'
                                ">
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12"
                        sm="5">
                        <div class="d-flex justify-space-between text-center text-subtitle-2">
                          Operater: {{ receipt.seller_id }}
                        </div>
                        <div class="d-flex justify-space-between text-center text-subtitle-2">
                          Način plaćanja: {{ receipt.payment_method }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-list>
                      <v-row>
                        <v-col>
                          <div class="text-center text-subtitle-2 one-liner-text font-weight-bold">
                            R. br.
                          </div>
                        </v-col>
                        <v-col>
                          <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                            Naziv
                          </div>
                        </v-col>
                        <v-col>
                          <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                            Količina
                          </div>
                        </v-col>
                        <v-col>
                          <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                            Jedinična cijena
                          </div>
                        </v-col>
                        <v-col>
                          <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                            Cijena
                          </div>
                        </v-col>
                      </v-row>
                      <v-list-item v-for="(item, index) in receipt.items"
                        :key="index"
                        class="pa-0">
                        <v-row class="pa-0">
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ index + 1 }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ item.name }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ item.amt / multiplier }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{
                                item.single_item_price
                                | money(100, receipt.currency)
                              }}
                            </div>
                          </v-col>
                          <v-col>
                            <div class="text-center text-subtitle-2">
                              {{ item.price | money(100, receipt.currency) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <infinite-loading ref="InfiniteLoading"
            @infinite="infiniteHandler"
            spinner="waveDots">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </v-card-text>
      </v-card>
      <payment-change ref="paymentChanges"
        @receiptEdit="onReceiptEdit"
        @reprintReceipt="onReceiptReprint"></payment-change>
      <v-overlay v-if="loading"
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        style="z-index: 100">
        <div v-if="loading"
          class="text-center"
          style="
            position: absolute;
            z-index: 101;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
          <v-progress-circular indeterminate
            size="86"
            color="primary">
          </v-progress-circular>
        </div>
      </v-overlay>
    </v-dialog>
    <v-container class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }">
      <div class="kbd-header"></div>
      <vue-touch-keyboard :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input" />
    </v-container>
  </div>
</template>

<script>
import { df, auth } from '@/plugins/firebase'
import { clone, toISOLocal } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings'
import { reprint } from '@/libs/bixolon/reprint'
import { print58 } from '@/libs/bixolon/print58'
import state from '@/state'
import InfiniteLoading from 'vue-infinite-loading'
import { v4 as uuidv4 } from 'uuid'
import Confirm from '@/components/Confirm.vue'
import PaymentChange from '@/modules/company/components/PaymentChange'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'

export default {
  components: {
    InfiniteLoading,
    Confirm,
    PaymentChange
  },
  mixins: [applicationSettings, onScreenKeyboard],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data () {
    return {
      dialogOpen: false,
      loading: false,
      receipts: [],
      unfiskReceipts: [],
      location: {},
      statusFilter: {},
      oibFilter: undefined,
      oibFilterManual: '',
      receiptNumberFilter: '',
      receiptNumberParsed: null,
      lastVisible: {},
      listeners: [],
      reciptOffer: {},
      associates: [],
      multiplier: 0,
      filtersExpanded: false,
      absolute: true,
      opacity: 0.46,
      overlay: true,
      searchNumber: '',
      register: null,
      document: null,
      last_balance_timestamp: new Date(new Date().setHours(0, 0, 0, 0))
    }
  },
  computed: {
    reciptOfferChoices () {
      return [
        { name: this.$tc('$vuetify.receipt', 2), value: 'receipts' },
        { name: this.$tc('$vuetify.offer', 2), value: 'offers' },
        { name: 'Virman', value: 'virmans' }
      ]
    },
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    }
  },
  methods: {
    async open () {
      console.log('open')
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      this.register = register.data()
      this.last_balance_timestamp = await this.register.last_balance_timestamp
      this.setEnterAction(this.doSearch)
      this.setPosition(this.$refs.keyboard, '117px', '502px')
      this.getAssociates()
      this.getMultiplier()
      this.locations = [
        { name: this.$t('$vuetify.all'), id: '', location_id: '' }
      ]
      this.location = this.locations[0]

      this.reciptOffer = this.reciptOfferChoices[0].value

      const docRef = df.doc(
        `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
      )
      docRef.onSnapshot(doc => {
        if (
          doc &&
          doc.data() &&
          doc.data().locations &&
          Object.keys(doc.data().locations).length > 0
        ) {
          this.locs = Object.keys(doc.data().locations).map(key => {
            return doc.data().locations[key]
          })
        }
      })

      this.oibFilter = undefined
      this.oibFilterManual = ''
      this.receiptNumberFilter = ''

      this.dialogOpen = true
    },
    close () {
      this.dialogOpen = false
    },
    applyFilter () {
      this.lastVisible = {}
      this.receipts = []
      this.unfiskReceipts = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },
    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    fiscalizeAll () {
      const that = this
      try {
        this.selected = {}
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'fiscalize_bulk',
            resource_id: '',
            params: {
              company_id: state.getCurrentCompany().id
            }
          }
        }

        that.loading = true
        that.$refs.confirmFiscalize.close()
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
          })
          .catch(function (err) {
            that.loading = false
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().header || !doc.data().header.code || doc.data().header.code !== 200) {
              that.showMsgBox({
                text: doc.data().header.error ? doc.data().header.error : 'Nije uspjela fiskalizacija računa!',
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.loading = false
              that.lastVisible = {}
              that.receipts = []
              that.unfiskReceipts = []
              that.$refs.InfiniteLoading.stateChanger.reset()
              that.$refs.confirmFiscalize.close()
              return
            }

            that.loading = false
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
          }
        })
      } catch (err) {
        that.loading = false

        that.lastVisible = {}
        that.receipts = []
        that.unfiskReceipts = []
        that.$refs.InfiniteLoading.stateChanger.reset()
        that.$refs.confirmFiscalize.close()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    infiniteHandler ($state) {
      const that = this
      const where = that.location ? that.location.location_id : ''

      let receiptNumberParsed
      if (this.receiptNumberFilter !== '') {
        receiptNumberParsed = this.parseReceiptNumber(this.receiptNumberFilter)
      }
      let query = df
        .collection(
          `${this.reciptOffer}/${state.getCurrentCompany().id}/${this.reciptOffer
          }`
        )
        .orderBy('issue_date_time', 'desc')
        .limit(10)
        .startAfter(that.lastVisible)

      if (where) {
        query = query.where('designation.location_id', '==', where)
      }

      query = query.where('issue_date_time', '>', this.register.last_balance_timestamp)

      if (receiptNumberParsed) {
        query = query
          .where('designation.number', '==', receiptNumberParsed.number)
          .where('designation.location_id', '==', receiptNumberParsed.location_id)
          .where('designation.register_number', '==', receiptNumberParsed.register_number)
      }
      query.onSnapshot(
        doc => {
          doc.docChanges().forEach(change => {
            that.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              that.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
      )
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())
          const issueDate = new Date(_document.issue_date_time * 1000)
          const nowDate = new Date(Date.now())

          switch (_document.type.flag) {
            case 'R':
            case 'R1':
            case 'R2':
            case 'BO':
              _document.receiptOptions = [
                { title: 'Preuzmi račun', action: this.printReceipt },
                { title: 'Storniraj račun', action: this.stornoReceipt },
                { title: 'Ponovi ispis', action: this.reprintReceipt },
                { title: 'Konz. jela i pića', action: this.reprintReceiptKonzumacijaJelaIpica }
              ]

              if (_document.payment_method !== 'TRANSAKCIJSKI RAČUN' && _document.jir === '') {
                _document.receiptOptions = _document.receiptOptions.filter((options) =>
                  options.title !== 'Storniraj račun')
              }

              if (
                _document.payment?.current_status !== 'PAID' &&
                _document.payment_method === 'TRANSAKCIJSKI RAČUN'
              ) {
                _document.receiptOptions.push({
                  title: 'Plati račun',
                  action: this.payReceipt
                })
                _document.receiptOptions.push({
                  title: 'Opomeni korisnika',
                  action: this.sendWarningToClient
                })
              }

              if (
                _document.payment?.current_status !== 'UNPAID' &&
                _document.payment_method === 'TRANSAKCIJSKI RAČUN'
              ) {
                _document.receiptOptions.push({
                  title: 'Prebaci status u neplaćeno',
                  action: this.statusToUnpaid
                })
              }

              if (
                _document.payment?.current_status === 'PAID' &&
                _document.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                _document.fiscalize === true && _document.jir !== '' &&
                issueDate.getFullYear() === nowDate.getFullYear() &&
                issueDate.getMonth() === nowDate.getMonth() &&
                issueDate.getDate() === nowDate.getDate()

              ) {
                _document.receiptOptions.push({
                  title: 'Promijeni način plaćanja',
                  action: this.changePayment
                })
              }

              if (
                _document.payment?.current_status === 'REVERSED') {
                _document.receiptOptions = _document.receiptOptions.filter((options) =>
                  options.title !== 'Storniraj račun')
              }
              break
            case 'STORNO':
              _document.receiptOptions = [
                { title: 'Preuzmi račun', action: this.printReceipt }
              ]
              break
            case 'P':
              _document.receiptOptions = [
                { title: 'Preuzmi ponudu', action: this.printReceipt },
                {
                  title: 'Izradi račun iz ponude',
                  action: this.generateReceipt
                }
              ]
              break
            case 'V':
              _document.receiptOptions = [
                { title: 'Preuzmi virman', action: this.printReceipt },
                {
                  title: 'Izradi račun iz virmana',
                  action: this.generateReceipt
                }
              ]
          }

          let rcptSeparator = '-'
          if (_document.company_details.rcpt_separator !== undefined && _document.company_details.rcpt_separator !== '') {
            rcptSeparator = _document.company_details.rcpt_separator
          }

          if (_document.designation) {
            _document.receiptNumber = `${_document.designation.number}${rcptSeparator}${_document.designation.location_id}${rcptSeparator}${_document.designation.register_number}`
          }
          if (_document.issue_date_time) {
            _document.time = _document.issue_date_time.seconds
          }
          if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
            _document.customer = _document.type.payer_name
          } else {
            _document.customer = this.$t('$vuetify.receipts.retailCustomer')
          }

          _document.show = false

          this.receipts.push({
            ..._document,
            ...{ [`hover-${change.doc.data().id}`]: false }
          })
          // }

          this.receipts.sort((a, b) => b.issue_date_time - a.issue_date_time) // b - a for reverse sort
          this.$forceUpdate()
        }
      }
      if (change.type === 'modified') {
        // TODO: duplicated code, make 1 fun
        this.receipts = this.receipts.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            const _document = clone(change.doc.data())
            const issueDate = new Date(_document.issue_date_time * 1000)
            const nowDate = new Date(Date.now())
            switch (_document.type.flag) {
              case 'R':
              case 'R1':
              case 'R2':
              case 'BO':
                _document.receiptOptions = [
                  { title: 'Preuzmi račun', action: this.printReceipt },
                  { title: 'Storniraj račun', action: this.stornoReceipt },
                  { title: 'Ponovi ispis', action: this.reprintReceipt },
                  { title: 'Konz. jela i pića', action: this.reprintReceiptKonzumacijaJelaIpica }
                ]

                if (
                  _document.payment?.current_status !== 'PAID' &&
                  _document.payment_method === 'TRANSAKCIJSKI RAČUN'
                ) {
                  _document.receiptOptions.push({
                    title: 'Plati račun',
                    action: this.payReceipt
                  })
                  _document.receiptOptions.push({
                    title: 'Opomeni korisnika',
                    action: this.sendWarningToClient
                  })
                }

                if (
                  _document.payment?.current_status !== 'UNPAID' &&
                  _document.payment_method === 'TRANSAKCIJSKI RAČUN'
                ) {
                  _document.receiptOptions.push({
                    title: 'Prebaci status u neplaćeno',
                    action: this.statusToUnpaid
                  })
                }

                if (
                  _document.payment?.current_status === 'PAID' &&
                  _document.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                  _document.fiscalize === true && _document.jir !== '' &&
                  issueDate.getFullYear() === nowDate.getFullYear() &&
                  issueDate.getMonth() === nowDate.getMonth() &&
                  issueDate.getDate() === nowDate.getDate()

                ) {
                  _document.receiptOptions.push({
                    title: 'Promijeni način plaćanja',
                    action: this.changePayment
                  })
                }

                break
              case 'STORNO':
                _document.receiptOptions = [
                  { title: 'Preuzmi račun', action: this.printReceipt }
                ]
                break
              case 'P':
                _document.receiptOptions = [
                  { title: 'Preuzmi ponudu', action: this.printReceipt },
                  {
                    title: 'Izradi račun iz ponude',
                    action: this.generateReceipt
                  }
                ]
            }
            if (_document.designation) {
              _document.receiptNumber = `${_document.designation.number}-${_document.designation.location_id}-${_document.designation.register_number}`
            }
            if (_document.issue_date_time) {
              _document.time = _document.issue_date_time.seconds
            }
            if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
              _document.customer = _document.type.payer_name
            } else {
              _document.customer = this.$t('$vuetify.receipts.retailCustomer')
            }
            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.receipts = this.receipts.filter((document, index, self) =>
        index === self.findIndex((t) => (
          t.id === document.id
        ))
      )
    },
    getReceiptsOrOffers () {
      this.lastVisible = ''
      this.receipts = []
      this.unfiskReceipts = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    stornoReceipt (receipt) {
      this.$refs.confirmStorno.open(
        '',
        `Storniraj račun ${receipt.receiptNumber}?`,
        { confirmText: 'Da', cancelText: 'Ne' }
      )
      this.selected = receipt
    },
    printReceipt (receipt, preview = false) {
      const that = this
      try {
        that.loading = true
        const reqId = uuidv4()
        let entity = 'receipt'
        if (this.reciptOffer === 'offers') {
          entity = 'offer'
        }

        if (this.reciptOffer === 'virmans') {
          entity = 'virman'
        }

        let printSize = null
        if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
          printSize = state.getPrinter().value
        } else {
          printSize = 'BIXOLON80'
        }

        const payload = {
          action: {
            operation: 'get',
            entity: entity,
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id,
              print_size: printSize
            }
          }
        }

        if (that.reciptOffer === 'offers') {
          payload.action.entity = 'offer'
          payload.action.params.print_size = printSize
        }

        if (that.reciptOffer === 'virmans') {
          payload.action.entity = 'virman'
          payload.action.params.print_size = printSize
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          })
          .then(function () { })
          .catch(function (err) {
            that.loading = false
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().body || !doc.data().body.document) {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.nullReceipt'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.loading = false
              return
            }

            if (preview) {
              that.openPdfPrint(doc.data().body.document)
              that.loading = false
            } else {
              const linkSource = `data:application/pdf;base64,${doc.data().body.document
                }`
              const downloadLink = document.createElement('a')
              const fileName = `${receipt.receiptNumber}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              that.loading = false
            }
          }
        })
      } catch (err) {
        that.loading = false
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    onReceiptReprint (receipt) {
      const printerData = state.getSelectedPrinter()
      if (!printerData.width || printerData.width === '80') {
        reprint(printerData, receipt, 2)
      } else {
        print58(printerData, receipt, 2)
      }
    },
    reprintReceipt (receipt) {
      const printerData = state.getSelectedPrinter()
      // console.log('printerData', printerData)
      if (!printerData.width || printerData.width === '80') {
        reprint(printerData, receipt, 0)
      } else {
        print58(printerData, receipt, 0)
      }
    },
    reprintReceiptKonzumacijaJelaIpica (receipt) {
      const printerData = state.getSelectedPrinter()
      if (!printerData.width || printerData.width === '80') {
        reprint(printerData, receipt, 1)
      } else {
        print58(printerData, receipt, 1)
      }
    },
    cancelReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        let receiptNumber = receipt.designation ? receipt.designation.number : 0
        df.doc(`cash_registers/${receipt.designation.register_id}`)
          .get()
          .then(reg => {
            if (reg && reg.exists) {
              if (reg.data()) {
                if (reg.data().status !== 'OK') {
                  this.$refs.confirmStorno.close()
                  that.showMsgBox({
                    text: that.$t('$vuetify.errors.unknownRegister'),
                    actions: ['cancel'],
                    cancelBtnText: that.$t('$vuetify.close'),
                    color: 'error'
                  })
                  return
                }
                if (this.reciptOffer === 'receipts') {
                  receiptNumber = reg.data().bill_sequence || 0
                } else if (this.reciptOffer === 'offers') {
                  receiptNumber = reg.data().offer_sequence || 0
                }
                const payload = {
                  action: {
                    operation: 'set',
                    entity: 'storno',
                    params: {
                      company_id: state.getCurrentCompany().id,
                      location_id: receipt.location_details.id,
                      receipt_id: receipt.id,
                      register_id: receipt.designation.register_id,
                      issue_date_time: parseInt(
                        (new Date().getTime() / 1000).toFixed(0)
                      ),
                      number: receiptNumber + 1
                      // seller_id: auth.currentUser.uid,
                    }
                  }
                }

                that.loading = true
                that.$refs.confirmStorno.close()
                df.doc(`request/${reqId}`)
                  .set({
                    user_id: `${auth.currentUser.uid}`,
                    device_id: 'web',
                    created: `${new Date().getTime()}`,
                    type: 'receipt',
                    payload: btoa(
                      unescape(encodeURIComponent(JSON.stringify(payload)))
                    )
                  })
                  .then(function () {
                    setTimeout(() => {
                      that.loading = false
                      that.lastVisible = {}
                      that.receipts = []
                      that.unfiskReceipts = []
                      that.$refs.InfiniteLoading.stateChanger.reset()
                    }, 5500)
                  })
                  .catch(function (err) {
                    that.loading = false
                    that.showMsgBox({
                      text: err && err !== '' ? err : 'An error has occurred',
                      actions: ['cancel'],
                      cancelBtnText: 'OK',
                      color: 'error'
                    })
                  })
              }
            } else {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.unknownRegister'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.loading = false
              this.$refs.confirmStorno.close()
            }
          })
      } catch (err) {
        this.$refs.confirmStorno.close()
        that.loading = false
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    generateReceipt (receipt) {
      if (receipt && receipt.type && receipt.type.flag && receipt.type.flag === 'V') {
        this.$refs.receiptFromVirman.open(receipt, this.multiplier)
      } else {
        this.$refs.receiptFromOffer.open(receipt, this.multiplier)
      }
    },
    payReceipt (receipt) {
      this.$refs.payReceipt.open(receipt)
    },
    onReceiptEdit (receipt) {
      this.receipts = this.receipts.map(r => {
        if (r.id === receipt.id) {
          r.payment.current_status = 'PROCESSING'
        }
        return r
      })
    },
    sendWarningToClient (receipt) {
      this.$refs.clientWarning.open(receipt, {
        action: 'WARNING_SENT',
        title: 'Opomeni korisnika',
        okBtn: 'Opomeni'
      })
    },
    statusToUnpaid (receipt) {
      this.$refs.statusToUnpaid.open(receipt)
    },
    changePayment (receipt) {
      this.$refs.paymentChanges.open(receipt)
    },
    onExpandFilters () {
      this.filtersExpanded = !this.filtersExpanded
      this.expandIcon = this.filtersExpanded ? 'mdi-minus' : 'mdi-plus'
    },

    parseReceiptNumber (receiptNumber) {
      receiptNumber = receiptNumber.replaceAll('/', '-')
      const parts = receiptNumber.split('-')
      if (parts.length !== 3) {
        return {
          number: 0,
          location_id: '',
          register_number: 0
        }
      }
      return { number: parseInt(parts[0]), location_id: parts[1], register_number: parseInt(parts[2]) }
    },

    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)
            this.associates = clone(this.defaultAssociates)
          }
        })
    },
    getMultiplier () {
      df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
    },

    issueBefore (issueDateTime) {
      const tmp = new Date(
        toISOLocal(
          new Date(issueDateTime * 1000)
        )
      )

      tmp.setDate(tmp.getDate() + 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )

      return before
    },
    resetField (field) {
      field === 'oib' ? this.oibFilterManual = '' : this.oibFilter = undefined
      this.applyFilter()
    },

    doSearch () {
      if (!/^(\s*|\d+)$/.test(this.searchNumber)) {
        this.searchNumber = ''
        return
      }
      this.lastVisible = {}
      this.receipts = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    }
  }
}
</script>

<style scoped>
.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
}

.panel-container {
  margin-top: 1rem;
}

.text-wrap {
  width: min-content;
  margin: auto;
}

.panel-cell {
  padding: 0 1px 1px 0;
}

.active {
  background-color: var(--v-accent-darken3);
  color: white;
}

.label {
  font-size: 0.6rem;
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
