<template>
  <div>
    <v-progress-linear v-if="!loaded" indeterminate></v-progress-linear>
    <v-row v-for="(row, idxr) in rows" :key="idxr" class="panel-row">
      <v-col
        v-for="(item, idxc) in row.cols"
        :key="idxc"
        :cols="item.colspan"
        class="panel-cell"
        align="center"
      >
        <v-btn
          style="display: inline-block"
          :disabled="!editMode && item.id === ''"
          block
          tile
          depressed
          min-height="3.5rem"
          :color="item.color"
          @click="onCellClick(item)"
        >
          <span
            class="text-wrap label"
            :style="`color: ${item.foregroundColor}`"
            >{{ item.label }}</span
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <!-- <div class="invoice-table-wrapper"> -->
        <v-data-table
          v-if="!ordersView"
          class="invoice-table"
          :height="tableHeight"
          fixed-header
          :headers="headers"
          :items="invoice.items"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar
              dense
              flat
              outlined
              style="border-left: 0; height: 3.4rem"
              ><span
                style="
                  font-weight: bold;
                  text-transform: capitalize;
                  font-size: 16px;
                  padding: 0 20px 0 0;
                "
                >{{
                  selectedTable.user_name ? selectedTable.user_name : ""
                }}</span
              >
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <div
                  style="
                    padding-top: 1.5rem;
                    padding-right: 2rem;
                    padding-left: 2rem;
                  "
                >
                  <v-switch
                    v-model="switchOnOrdersTable"
                    @change="onOrdersChange"
                  ></v-switch>
                </div>
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`header.controls`]="{}">
            <v-row class="justify-end pr-1">
              <v-btn class="mr-3" icon @click="openDiscountSelectionDialog()">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template #[`item.name`]="{ item }">
            <div
              v-if="!showEdit(item)"
              class="invoice-row"
              style="min-width: 15rem; max-width: 15rem"
              :style="
                showPencil(item)
                  ? { 'text-decoration': 'underline', cursor: 'pointer' }
                  : { 'text-decoration': 'none' }
              "
              @mouseover="onNameMouseover(item)"
              @mouseleave="onNameMouseover(item)"
              @click="onNameClick(item)"
            >
              {{ item.name }}
              <v-icon v-if="showPencil(item)" class="ml-2">mdi-pencil</v-icon>
            </div>
            <div v-else>
              <v-text-field
                autofocus
                class="invoice-row"
                v-model="item.name"
                append-icon="mdi-check"
                @click:append="finishEdit(item)"
                @keypress.enter="finishEdit(item)"
              >
              </v-text-field>
            </div>
          </template>
          <template #[`item.selling_unit`]="{ item }">
            <div class="invoice-row" style="min-width: 5rem; max-width: 5rem">
              {{ item.selling_unit }}
            </div>
          </template>
          <template #[`item.unitPrice`]="{ item }">
            <div class="invoice-row" style="min-width: 3rem; max-width: 3rem">
              {{ $options.filters.money(item.unitPrice, 100, currency) }}
            </div>
          </template>
          <template #[`item.discountedUnitPrice`]="{ item }">
            <div class="invoice-row" style="min-width: 3rem; max-width: 3rem">
              {{
                $options.filters.money(item.discountedUnitPrice, 100, currency)
              }}
            </div>
          </template>
          <template #[`item.total`]="{ item }">
            <div class="invoice-row" style="min-width: 3rem; max-width: 3rem">
              {{ $options.filters.money(item.total, 100, currency) }}
            </div>
          </template>
          <template #[`item.quantity`]="{ item }">
            <v-text-field
              style="
                max-width: 2rem;
                height: 24px !important;
                margin-top: -28px;
              "
              class="invoice-row"
              v-model="item.quantity"
              :disabled="item.type && item.type === 'menu-product'"
              @change="recalculate()"
            ></v-text-field>
          </template>
          <template #[`item.controls`]="props">
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-btn
                    icon
                    :disabled="
                      !props.item || props.item.type === 'menu-product'
                    "
                    color="red"
                    @click="onRowBtnClick(props.item, 'decrement')"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    :disabled="
                      !props.item || props.item.type === 'menu-product'
                    "
                    color="red"
                    @click="onRowBtnClick(props.item, 'increment')"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="mr-1">
                  <v-btn
                    icon
                    @click="openItemsDiscountSelectionDialog(props.item)"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>

        <v-data-table
          v-if="selectedTable && ordersView"
          :headers="orderHeaders"
          :items="selectedTable.orders"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          hide-default-footer
          fixed-header
          :items-per-page="-1"
          item-key="id"
          show-expand
          class="invoice-table"
        >
          <template v-slot:top>
            <v-toolbar dense flat outlined rounded shaped>
              <span
                style="
                  font-weight: bold;
                  text-transform: capitalize;
                  font-size: 16px;
                  padding: 0 20px 0 0;
                "
                >{{
                  selectedTable.user_name ? selectedTable.user_name : ""
                }}</span
              >
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      style="
                        padding-top: 1.5rem;
                        padding-right: 2rem;
                        padding-left: 2rem;
                      "
                    >
                      <v-switch
                        v-model="switchOnOrdersTable"
                        @change="onOrdersChange"
                      ></v-switch>
                    </div>
                  </template>
                  <span>{{
                    switchOnOrdersTable ? "Narudžbe" : "Blagajna"
                  }}</span>
                </v-tooltip>
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="orderHeaders.length">
              <v-row class="d-flex" flat>
                <v-col cols="4" class="d-flex justify-center pt-5 pl-5"
                  >Ime artikla</v-col
                >
                <v-col class="d-flex justify-center pt-5 pr-5 pl-5"
                  >Količina artikla</v-col
                >
                <v-col cols="4" class="d-flex justify-center pt-5"
                  >Jedinična cijena</v-col
                >
              </v-row>
              <v-row
                v-for="(it, index) in item.order_items"
                :key="item.id + it.id + index"
                flat
                class="d-flex"
              >
                <v-col cols="4" class="d-flex justify-center pt-5 pl-5">{{
                  it.name
                }}</v-col>
                <v-col cols="4" class="d-flex justify-center pt-5 pr-5 pl-5">{{
                  it.quantity
                }}</v-col>
                <v-col cols="4" class="d-flex justify-center pt-5">{{
                  $options.filters.money(it.discountedUnitPrice, 100, currency)
                }}</v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <!-- </div> -->
      </v-col>
      <v-col cols="4">
        <panel-payment-selection
          v-if="
            registerPaymentPanel === '' || registerPaymentPanel === 'default'
          "
          style="position: relative; right: 0"
          :data="invoice"
          :selectedPrinter="selectedPrinter"
          @selectTable="onSelectTable"
          @recalculate="recalculate"
          @clearSelection="clearSelection"
          @openCloseBalanceDialog="onOpenCloseBalanceDialog"
          @openSearchItemsDialog="onOpenSearchItemsDialog"
          @openReceiptsDialog="onOpenReceiptsDialog"
          @sendNotes="onSendNotes"
          @resetNotes="onResetNodes"
          @createNewTable="onCreateNewTable"
          @addWriteInProduct="onAddWriteInProduct"
        ></panel-payment-selection>

        <panel-payment-selection-simple
          v-if="registerPaymentPanel === 'simple'"
          style="position: relative; right: 0"
          :data="invoice"
          :selectedPrinter="selectedPrinter"
          @selectTable="onSelectTable"
          @recalculate="recalculate"
          @openCloseBalanceDialog="onOpenCloseBalanceDialog"
          @openSearchItemsDialog="onOpenSearchItemsDialog"
          @openReceiptsDialog="onOpenReceiptsDialog"
          @sendNotes="onSendNotes"
          @resetNotes="onResetNodes"
        ></panel-payment-selection-simple>
      </v-col>
    </v-row>
    <div class="total-row">
      <v-row>
        <!--
        {{ eurTotalDiscount | money(1, currency) }} -->

        <v-col align="start" cols="5">
          <h2 style="margin-left: 1rem; text-transform: uppercase">
            {{ `${selectedTable.name}` }}
          </h2>
        </v-col>
        <v-col class="pt-0" cols="7">
          <h4 style="text-transform: uppercase">
            <!-- {{ "Tečaj 1 EUR = 7,53450 HRK" }}&nbsp;&nbsp;&nbsp;&nbsp; -->
            Popust:&nbsp;
            {{ $options.filters.money(totalDiscount, 100, currency) }}
          </h4>
          <h3 style="text-transform: uppercase">
            {{ $t("$vuetify.receipts.receiptPrice") }}:
            {{ $options.filters.money(total, 100, currency) }}&nbsp;&nbsp;
            {{ $options.filters.money(hrkTotalDiscount, 100, "HRK") }}
          </h3>
        </v-col>
      </v-row>
    </div>
    <add-item-to-panel
      :addItemDialogOpen="addItemDialogOpen"
      :categories="categories"
      :selectedTile="selectedTile"
      @addCategoryToPanel="onAddCategoryToPanel"
      @addProductToPanel="onAddProductToPanel"
      @addItemDialogClose="onAddItemDialogClose"
    ></add-item-to-panel>
    <edit-panel-item
      v-if="editItemDialogOpen"
      :categories="categories"
      :editItem="editItem"
      :editItemDialogOpen="editItemDialogOpen"
      @deleteItem="onDeleteItem"
      @changeCategoryOnPanel="onChangeCategoryOnPanel"
      @changeProductOnPanel="onChangeProductOnPanel"
      @editItemDialogClose="onEditItemDialogClose"
    ></edit-panel-item>
    <select-product-from-category
      :selectProductDialogOpen="selectProductDialogOpen"
      :selectedCategoryId="selectedCategoryId"
      @addProduct="addProduct"
      @addItemDialogClose="onAddProductDialogClose"
    ></select-product-from-category>
    <search-items-dialog
      v-if="searchItemsDialogOpen"
      @addProduct="addProduct"
      @closeSearchItemsDialog="onCloseSearchItemsDialog"
    ></search-items-dialog>
    <receipts-dialog
      v-if="receiptsDialogOpen"
      @closeReceiptsDialog="onCloseReceiptsDialog"
    ></receipts-dialog>
    <add-note-dialog ref="addNoteDialog"></add-note-dialog>
    <add-note-article-dialog
      ref="addNoteArticleDialog"
    ></add-note-article-dialog>
    <menu-dialog ref="menuDialog" @add="addMenuProduct"></menu-dialog>
    <close-balance ref="closeBalance"></close-balance>
    <add-discount-form
      @add="onAddDiscount"
      ref="addDiscountForm"
    ></add-discount-form>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
      <close-balance ref="closeBalance"></close-balance>
    </v-container>
    <show-error ref="showError"></show-error>
    <add-manual-price ref="addManualPrice" @add="onAddPrice"></add-manual-price>
    <discount-selection-dialog
      ref="discountSelectionDialog"
      :generalMenu="generalMenu"
      :lastInsertBy="lastInsertBy"
      @generalMenuClick="generalMenuClick"
    ></discount-selection-dialog>
    <item-discount-selection-dialog
      ref="itemDiscountSelectionDialog"
      @itemMenuClick="itemMenuClick"
    ></item-discount-selection-dialog>
  </div>
</template>

<script>
import Table from '@/model/Table'
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'
// import { checkJspmStatus } from '@/libs/jsPrintManager/utils'
import ShowError from '@/components/ShowError'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import AddManualPrice from '@/modules/cash-register/components/AddManualPrice'
import MenuDialog from '@/modules/cash-register/components/MenuDialog'
import PanelPaymentSelection from '@/modules/cash-register/components/PanelPaymentSelection'
import PanelPaymentSelectionSimple from '@/modules/cash-register/components/PanelPaymentSelectionSimple'
import CloseBalance from '@/modules/cash-register/components/closeBalance/CloseBalance'
import AddItemToPanel from '@/modules/cash-register/components/dialogs/AddItemToPanel'
import AddNoteArticleDialog from '@/modules/cash-register/components/dialogs/AddNoteArticleDialog'
import AddNoteDialog from '@/modules/cash-register/components/dialogs/AddNoteDialog'
import DiscountSelectionDialog from '@/modules/cash-register/components/dialogs/DiscountSelectionDialog'
import EditPanelItem from '@/modules/cash-register/components/dialogs/EditPanelItem'
import ItemDiscountSelectionDialog from '@/modules/cash-register/components/dialogs/ItemDiscountSelectionDialog'
import ReceiptsDialog from '@/modules/cash-register/components/dialogs/ReceiptsDialog'
import SearchItemsDialog from '@/modules/cash-register/components/dialogs/SearchItemsDialog'
import SelectProductFromCategory from '@/modules/cash-register/components/dialogs/SelectProductFromCategory'
import EventBus from '@/plugins/event-bus'
import AddDiscountForm from './receipt-calculator-steps/AddDiscountForm.vue'

export default {
  components: {
    AddItemToPanel,
    EditPanelItem,
    SelectProductFromCategory,
    MenuDialog,
    PanelPaymentSelection,
    AddDiscountForm,
    SearchItemsDialog,
    ReceiptsDialog,
    CloseBalance,
    AddNoteDialog,
    AddNoteArticleDialog,
    AddManualPrice,
    PanelPaymentSelectionSimple,
    ShowError,
    DiscountSelectionDialog,
    ItemDiscountSelectionDialog
  },
  inject: ['showMsgBox', 'hideLoader'],
  mixins: [onScreenKeyboard],
  data () {
    return {
      expanded: [],
      user: undefined,
      singleExpand: false,
      switchOnOrdersTable: false,
      addItemDialogOpen: false,
      editItemDialogOpen: false,
      selectProductDialogOpen: false,
      searchItemsDialogOpen: false,
      receiptsDialogOpen: false,
      cashReg: {},
      headers: [
        {
          text: 'Rb.',
          align: 'start',
          sortable: false,
          value: 'rbr'
        },
        { text: 'Naziv', value: 'name', sortable: false },
        { text: 'Količina', value: 'quantity', sortable: false },
        { text: 'Mj. jedinica', value: 'selling_unit', sortable: false },
        { text: 'Jed. cijena', value: 'unitPrice', sortable: false },
        { text: 'Jed. cijena s pop.', value: 'discountedUnitPrice', sortable: false },
        { text: 'Ukupno', value: 'total', sortable: false },
        { text: '', value: 'controls', sortable: false }
      ],
      orderHeaders: [
        {
          text: 'Rb.',
          align: 'start',
          value: 'order_number'
        },
        { text: 'Izmjenio', value: 'added_by_name' },
        { text: 'Status', value: 'order_status' },
        { text: '', value: 'data-table-expand' }

      ],
      selectedTile: undefined,
      selectedCategoryId: undefined,
      itemMouseover: [],
      itemEdit: [],
      savedName: '',
      invoice: {},
      rowCount: 4,
      rows: [],
      colCount: 12,
      loaded: false,
      selectedTable: {},
      editMode: false,
      ordersView: false,
      editItem: {},
      listeners: [],
      tableHeight: 0,
      barcode: [],
      currency: 'EUR',
      generalMenu: [
      ],
      lastInsertBy: '',
      registerPaymentPanel: ''
    }
  },
  watch: {
    invoice: {
      immediate: false,
      deep: true,
      handler () {
        this.recalculateRows()
        if (!this.invoice.discount) {
          if (state.getCashRegister().type === 'VP') {
            this.generalMenu = [
              { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' },
              { text: 'Dodaj napomenu', method: 'addNoteDialogOpen', icon: 'mdi-delete' }
            ]
          } else {
            this.generalMenu = [
              // { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
              { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' },
              { text: 'Dodaj napomenu', method: 'addNoteDialogOpen', icon: 'mdi-delete' }
            ]
          }
        } else {
          if (state.getCashRegister().type === 'VP') {
            this.generalMenu = [
              { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' },
              { text: 'Dodaj napomenu', method: 'addNoteDialogOpen', icon: 'mdi-delete' }
            ]
          } else {
            this.generalMenu = [
              // { text: 'Ukloni popust', method: 'removeDiscount', icon: 'mdi-delete' },
              { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' },
              { text: 'Dodaj napomenu', method: 'addNoteDialogOpen', icon: 'mdi-delete' }
            ]
          }
        }
      }
    }
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    selectedPrinter: {
      // passed over from PanelRegister
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hrkTotalDiscount () {
      const m = 100.00
      const temp = this.invoice.total > 0 ? this.invoice.total * 100 : this.invoice.total

      const inFloat = temp.toFixed(2)

      const trueVal = inFloat / 100
      let tConv = 0
      if (this.currency === 'HRK') {
        tConv = trueVal.toFixed(2) * 7.53450
      } else if (this.currency === 'EUR') {
        tConv = trueVal.toFixed(2) * 7.53450
      }

      const tOut = Math.round(tConv * m) / 100
      return tOut.toFixed(2)
    },
    total () {
      return this.invoice.total
    },
    totalDiscount () {
      return this.invoice.fullTotal - this.invoice.total
    }
  },
  methods: {
    openDiscountSelectionDialog () {
      this.$refs.discountSelectionDialog.open()
    },
    openItemsDiscountSelectionDialog (item) {
      this.$refs.itemDiscountSelectionDialog.open(item)
    },
    onAddWriteInProduct (item) {
      item.currency = this.currency
      const price = parseFloat(item.prices[item.currency].price) * 100
      item.unitPrice = price
      item.prices[this.currency].price = price
      item.quantity = parseFloat(item.quantity)
      item.total = price * item.quantity
      item.fullTotal = price * item.quantity
      item.discountedUnitPrice = price

      item.menu = [
        { text: 'Dodaj popust', method: 'addDiscount' },
        { text: 'Ukloni artikl', method: 'removeItem' },
        { text: 'Dodaj napomenu', method: 'addNoteArticleDialogOpen' }
      ]

      this.invoice.items.unshift(item)
      this.recalculate()
    },

    onNameMouseover (item) {
      if (this.itemMouseover.length === 0) {
        this.itemMouseover.push(item)
      } else {
        this.itemMouseover = []
      }
    },

    onNameClick (item) {
      if (this.itemEdit.length === 0) {
        this.savedName = item.name
        this.itemMouseover = []
        this.itemEdit.push(item)
      }
    },

    showEdit (item) {
      return this.itemEdit.length > 0 && this.itemEdit[0].id === item.id
    },

    showPencil (item) {
      return this.itemMouseover.length > 0 && this.itemMouseover[0].id === item.id
    },

    finishEdit (item) {
      if (item.name.length === 0) {
        item.name = this.savedName
      }
      this.itemEdit = []
    },
    // From splitTableDialog
    onCreateNewTable (sourceTable, destinationTable) {
      this.invoice.items = sourceTable.items
    },

    onClearTable () {
      this.selectedTable = new Table()
      this.invoice.items = []
    },

    onAddPrice (item) {
      const price = parseFloat(clone(item.price * 100).toFixed(2)) // all numbers are ok but for some reason number 555.55 is formated like a 555.5499999999 thats why this number is parsed again
      item.prices[this.currency].price = price

      var newItem = clone(item)
      if (this.cashReg.type === 'VP') {
        newItem.prices[this.currency].price = Math.round(parseFloat(newItem.prices[this.currency].price /
        (1 + newItem.prices[this.currency].taxes[0].rate / 10000)
        ), 2)
      }

      item.quantity = 1
      item.unitPrice = item.prices[this.currency].price
      item.discountedUnitPrice = item.unitPrice
      item.total = item.unitPrice
      this.invoice.items.unshift(item)
      this.recalculate()
    },

    setup () {
      for (let i = 0; i < this.rowCount; i++) {
        const row = {
          rowIndex: i,
          cols: []
        }

        for (let j = 0; j < this.colCount; j++) {
          row.cols.push({
            active: false,
            row_index: i,
            col_index: j,
            color: 'primary',
            colspan: 1,
            id: '',
            parent_id: '',
            label: '',
            type: ''
          })
        }

        this.rows.push(row)
      }
      this.populateData()
    },
    async populateData () {
      const query = df.collection(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows`)

      try {
        const doc = await query.get()

        for (let i = 0; i < this.rowCount; i++) {
          if (doc.docs[i]) {
            const row = doc.docs[i].data()
            for (let j = 0; j < this.colCount; j++) {
              if (row.cols && row.cols[j]) {
                const tile = row.cols[j]
                if (tile.active && this.rows[tile.row_index]) {
                  this.rows[tile.row_index].cols[tile.col_index] = tile
                } else {
                  this.rows[i].cols[j] = {
                    active: false,
                    row_index: i,
                    col_index: j,
                    color: 'primary',
                    colspan: 1,
                    id: '',
                    parent_id: '',
                    label: '',
                    type: ''
                  }
                }
              }
            }
          }
        }

        this.$forceUpdate() // Ažuriranje Vue DOM-a
        this.loaded = true
      } catch (error) {
        console.error('Error fetching configuration rows: ', error)
      }
    },
    recalculateRows () {
      let rbr = this.invoice.items.length + 1
      this.invoice.items.forEach((item) => {
        rbr -= 1
        item.rbr = rbr
      })
    },
    onOrdersChange () {
      EventBus.$emit('set-orders-view', this.switchOnOrdersTable)
    },

    onOpenSearchItemsDialog () {
      this.searchItemsDialogOpen = true
    },

    onOpenCloseBalanceDialog () {
      this.$refs.closeBalance.open()
    },

    onCloseSearchItemsDialog () {
      this.searchItemsDialogOpen = false
    },

    onOpenReceiptsDialog () {
      this.receiptsDialogOpen = true
    },

    onCloseReceiptsDialog () {
      this.receiptsDialogOpen = false
    },

    onSendNotes (_, order) {
      this.$refs.addNoteDialog.sendNotes(order)
    },

    onResetNodes () {
      this.$refs.addNoteDialog.resetNotes()
    },

    removeProducts () {
      this.invoice.items = []
      this.invoice.total = 0
      this.invoice.fullTotal = 0
      this.invoice.totalWithDiscount = 0
      this.invoice.totalShoppingCart = 0
      this.invoice.discount = undefined
      this.$forceUpdate()

      EventBus.$emit('clear-table')
    },
    generalMenuClick (method) {
      switch (method) {
        // case 'addDiscount':
        //   this.addDiscount(true)
        //   break
        // case 'removeDiscount':
        //   this.removeDiscount(true)
        //   break
        case 'removeProducts':
          this.removeProducts()
          break
        case 'addNoteDialogOpen':
          this.addNoteDialogOpen()
          break
      }
    },
    itemMenuClick (method, item) {
      switch (method) {
        case 'addDiscount':
          this.addDiscount(false, item.id)
          break
        case 'removeDiscount':
          this.removeDiscount(false, item.id)
          break
        case 'removeItem':
          this.onRowBtnClick(item, 'remove')
          break
        case 'addNoteArticleDialogOpen':
          this.addNoteArticleDialogOpen(item)
          break
      }
    },
    decideIcon (item) {
      if (item.discountedUnitPrice !== undefined && item.discountedUnitPrice > 0) {
        return 'mdi-delete'
      } else {
        return 'mdi-percent'
      }
    },
    onEditModeChange () {
      EventBus.$emit('set-mode', this.switchEditMode)
    },
    validateBarcode (b) {
      var Barcoder = require('barcoder')
      return Barcoder.validate(b)
    },
    barcodeFind (e) {
      if (e.key !== 'Enter') { this.barcode.push(e.key) }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.validateBarcode(this.barcode.join(''))) {
          df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
            .where('status', '==', 'OK')
            .where('active', '==', true)
            .where(`prices.${this.currency}.active`, '==', true)
            .where('barcodes', 'array-contains', this.barcode.join(''))
            .limit(1)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
                documentSnapshots.docs.forEach((item) => {
                  let it = item.data()

                  it.stock = it.stock / this.multiplier

                  if (it.warehouse_units !== undefined && it.warehouse_units.units !== undefined) {
                    Object.keys(it.warehouse_units.units).forEach(key => {
                      if (it.warehouse_units.units[key].prices !== undefined) {
                        let newIt = {}
                        newIt = clone(it)
                        newIt.prices = it.warehouse_units.units[key].prices
                        // if (it.warehouse_units.units[key].storage_unit && it.warehouse_units.units[key].storage_unit !== '') {
                        //   newIt.name = it.warehouse_units.units[key].storage_unit
                        // } else {
                        //   newIt.name = it.name
                        // }

                        var stUnit = it.warehouse_units.units[key].storage_unit
                        if (stUnit === '.kom') {
                          stUnit = ''
                        }

                        newIt.name = it.name + ' ' + stUnit
                        newIt.id = newIt.id + '.' + it.warehouse_units.units[key].id
                        it = newIt
                      }
                    })
                  }

                  it.type = 'barcode-added'
                  this.addProduct(it)
                })
              }
            })
        } this.barcode = []
      }, 100)
    },
    handleKeyEvents (e) {
      e = e || window.event
      this.barcodeFind(e)
    },
    addMenuProduct (invoiceItem) {
      const existingInvoiceItem = this.invoice.items.find(p =>
        p.id === invoiceItem.id
      )
      if (existingInvoiceItem) {
        const quantity = existingInvoiceItem.quantity + 1
        existingInvoiceItem.quantity = quantity
        existingInvoiceItem.unitPrice = existingInvoiceItem.prices[this.currency].price
        existingInvoiceItem.discountedUnitPrice = existingInvoiceItem.unitPrice
        existingInvoiceItem.total = existingInvoiceItem.unitPrice * quantity
        existingInvoiceItem.type = 'menu-product'

        if (invoiceItem.choosenProductOrder) {
          Object.keys(invoiceItem.choosenProductOrder).forEach(key => {
            if (!existingInvoiceItem.choosenProductOrder) {
              existingInvoiceItem.choosenProductOrder = {}
            }
            existingInvoiceItem.choosenProductOrder[key] = invoiceItem.choosenProductOrder[key]

            if (!existingInvoiceItem.newKeys) {
              existingInvoiceItem.newKeys = {}
            }

            existingInvoiceItem.newKeys[key] = {}
          })
        }

        if (invoiceItem.choosenTagOrder) {
          Object.keys(invoiceItem.choosenTagOrder).forEach(key => {
            if (!existingInvoiceItem.choosenTagOrder) {
              existingInvoiceItem.choosenTagOrder = {}
            }
            existingInvoiceItem.choosenTagOrder[key] = invoiceItem.choosenTagOrder[key]

            if (!existingInvoiceItem.newKeys) {
              existingInvoiceItem.newKeys = {}
            }

            existingInvoiceItem.newKeys[key] = {}
          })
        }

        this.$forceUpdate()
      } else {
        invoiceItem.total = 0
        invoiceItem.quantity = 1
        invoiceItem.unitPrice = invoiceItem.prices[this.currency].price
        invoiceItem.total = invoiceItem.unitPrice
        invoiceItem.discountedUnitPrice = invoiceItem.unitPrice
        invoiceItem.type = 'menu-product'
        invoiceItem.menu = [
          { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
          { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' },
          { text: 'Ukloni artikl', method: 'addNoteDialogOpen' }
        ]

        if (invoiceItem.choosenProductOrder) {
          Object.keys(invoiceItem.choosenProductOrder).forEach(key => {
            if (!invoiceItem.newKeys) {
              invoiceItem.newKeys = {}
            }

            invoiceItem.newKeys[key] = {}
          })
        }

        if (invoiceItem.choosenTagOrder) {
          Object.keys(invoiceItem.choosenTagOrder).forEach(key => {
            if (!invoiceItem.newKeys) {
              invoiceItem.newKeys = {}
            }

            invoiceItem.newKeys[key] = {}
          })
        }

        this.invoice.items.push(invoiceItem)
        const rbr = this.invoice.items.findIndex(p => p.id === invoiceItem.id) + 1
        this.invoice.items.find(p => p.id === invoiceItem.id).rbr = rbr
      }
      this.recalculate()
    },
    onCellClick (item) {
      this.selectedTile = item
      if (this.editMode) {
        if (item.active) {
          this.editPanelItem(item)
        } else {
          this.addPanelItem()
        }
      } else {
        if (item.type === 'category') {
          this.selectedCategoryId = item.id
          this.selectProductDialogOpen = true
        } else {
          this.addProduct(item)
        }
      }
    },
    onRowBtnClick (item, operation) {
      const idx = this.invoice.items.findIndex(p => p.id === item.id)
      switch (operation) {
        case 'increment':
          item.quantity = Number(item.quantity) + 1
          break
        case 'decrement':
          if (item.quantity === 1) {
            this.invoice.items.splice(idx, 1)
          } else if (item.quantity > 1) {
            item.quantity = Number(item.quantity) - 1
          }
          break
        case 'discount':
          if (item.discountedUnitPrice === undefined || item.discountedUnitPrice === 0) {
            this.addDiscount(false, item.id)
          } else {
            this.removeDiscount(false, item.id)
          }
          break
        case 'remove':
          this.invoice.items.splice(idx, 1)
          break
        default:
          break
      }
      if (this.invoice.items.length === 0) {
        this.invoice.total = 0
        this.invoice.fullTotal = 0
        this.invoice.totalWithDiscount = 0
        this.invoice.totalShoppingCart = 0
        this.invoice.discount = undefined
        this.$forceUpdate()

        EventBus.$emit('clear-table')
      }
      this.recalculate()
    },
    onAddCategoryToPanel (category, colspan, backgroundColor, foregroundColor) {
      this.addItemToPanel(category.id, '', colspan, category.name, backgroundColor, foregroundColor, 'category')
      this.$emit('updateItem', this.selectedTile, this.rows)
    },
    onAddProductToPanel (product, category, colspan, backgroundColor, foregroundColor) {
      this.addItemToPanel(product.id, category.id, colspan, product.name, backgroundColor, foregroundColor, 'product')
      if (product.products) {
        this.selectedTile.type = 'menu-product'
      }
      this.$emit('updateItem', this.selectedTile, this.rows)
    },
    addItemToPanel (id, categoryId, colspan, name, backgroundColor, foregroundColor, type) {
      this.selectedTile.colspan = colspan
      this.selectedTile.id = id
      this.selectedTile.parent_id = categoryId
      this.selectedTile.label = name
      this.selectedTile.type = type
      this.selectedTile.active = true
      this.selectedTile.color = backgroundColor
      this.selectedTile.foregroundColor = foregroundColor
    },
    onAddItemDialogClose () {
      this.addItemDialogOpen = false
    },
    onEditItemDialogClose () {
      this.editItemDialogOpen = false
    },
    onAddProductDialogClose () {
      this.selectProductDialogOpen = false
    },
    onSelectTable (table) {
      this.selectedTable = table
      this.invoice.items = [...this.selectedTable.items]
      this.$forceUpdate()
    },
    onChangeCategoryOnPanel (item, colspan, color, foregroundColor) {
      this.addItemToPanel(item.id, '', colspan, item.label, color, foregroundColor, 'category')
      this.$emit('updateItem', item, this.rows)
      this.editItemDialogOpen = false
    },
    onChangeProductOnPanel (item, colspan, color, foregroundColor) {
      this.addItemToPanel(item.id, item.parent_id, colspan, item.label, color, foregroundColor, 'product')
      this.$emit('updateItem', item, this.rows)
      this.editItemDialogOpen = false
    },
    updateTile (rowIndex, colIndex, props) {
      this.rows[rowIndex].cols[colIndex] = {
        active: props.active,
        row_index: rowIndex,
        col_index: colIndex,
        color: 'primary',
        colspan: props.colspan,
        id: props.id,
        label: props.label,
        type: props.type
      }
      this.$forceUpdate()
    },
    async onEditItem (item) {
      this.loaded = false
      const colsRef = df.doc(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows/row${item.row_index}`)
      await colsRef.update({ cols: { } })
      this.loaded = true
    },
    async onDeleteItem (item) {
      this.editItemDialogOpen = false
      try {
        this.loaded = false
        const colsRef = df.doc(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows/row${item.row_index}`)
        const document = await colsRef.get()
        const cols = document.data().cols
        const tileIndex = cols.findIndex(c => c.col_index === item.col_index)
        this.updateTile(item.row_index, item.col_index, { active: false, color: 'primary', colspan: 1, id: '', label: '', type: '' })
        this.markDeleted(cols, tileIndex)
        colsRef.update({ cols: cols })
        this.loaded = true
        console.info('Tile succefully deleted')
      } catch (err) {
        console.error(`Error deleting tile: ${err}`)
      }
    },
    markDeleted (cols, tileIndex) {
      const item = cols[tileIndex]
      item.active = false
      item.color = 'primary'
      item.colspan = 1
      item.id = ''
      item.label = ''
      item.type = ''
      item.parent_id = ''
    },
    editPanelItem (item) {
      this.editItem = item
      this.editItemDialogOpen = true
    },
    addPanelItem () {
      this.addItemDialogOpen = true
    },
    async addProduct (item) { // Note to myself: refactor!
      if (item.type === 'menu-product') {
        const product = await df.doc(`location_menu_pricelist/${state.getPointOfSale().id}/items/${item.id}`).get()
        const invoiceItem = product.data()

        this.$refs.menuDialog.open(invoiceItem, this.currency)
        return
      } else if (item.type === 'barcode-added') {
      // Added via barcode reader - already got full item
        const id = item.id
        let unitId = ''

        if (id.includes('.')) {
          unitId = id.split('.')[1]
        }

        let invoiceItem = item
        invoiceItem.total = 0

        let existingInvoiceItem = this.invoice.items.find(p => {
          return p.id === item.id
        })

        if (invoiceItem.warehouse_units !== undefined && invoiceItem.warehouse_units.units !== undefined) {
          Object.keys(invoiceItem.warehouse_units.units).forEach(key => {
            if (key === unitId && invoiceItem.warehouse_units.units[key].prices !== undefined) {
              let newIt = {}
              newIt = clone(invoiceItem)
              newIt.prices = invoiceItem.warehouse_units.units[key].prices
              newIt.name = invoiceItem.name
              invoiceItem = newIt
            }
          })
        }

        invoiceItem.menu = [
          { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
          { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' },
          { text: 'Dodaj napomenu', method: 'addNoteArticleDialogOpen' }
        ]

        if (existingInvoiceItem) {
          if (this.cashReg.type === 'VP') {
            existingInvoiceItem = this.getWholesaleItem(existingInvoiceItem)
          }
          const quantity = existingInvoiceItem.quantity + 1
          existingInvoiceItem.quantity = quantity
          existingInvoiceItem.unitPrice = existingInvoiceItem.prices[this.currency].price
          existingInvoiceItem.discountedUnitPrice = existingInvoiceItem.unitPrice
          existingInvoiceItem.total = existingInvoiceItem.unitPrice * quantity
        } else {
          if (this.cashReg.type === 'VP') {
            invoiceItem = this.getWholesaleItem(invoiceItem)
          }
          invoiceItem.quantity = 1
          invoiceItem.unitPrice = invoiceItem.prices[this.currency].price
          invoiceItem.discountedUnitPrice = invoiceItem.unitPrice
          invoiceItem.total = invoiceItem.unitPrice
          this.invoice.items.unshift(invoiceItem)
        }
      } else {
        if (item.id.includes('.')) {
          const ids = item.id.split('.')
          item.id = ids[0]
          item.unitId = ids[1]
        }
        const product = await df.doc(`location_pricelist/${state.getPointOfSale().id}/items/${item.id}`).get()
        if (item.unitId && item.unitId !== '') { item.id = item.id + '.' + item.unitId }
        let invoiceItem = product.data()

        if (item.unitId && item.unitId !== '') {
          if (invoiceItem.warehouse_units && invoiceItem.warehouse_units.units && invoiceItem.warehouse_units.units[item.unitId].prices) {
            let newProduct = {}
            newProduct = clone(invoiceItem)
            newProduct.prices = invoiceItem.warehouse_units.units[item.unitId].prices
            // if (invoiceItem.warehouse_units.units[item.unitId].storage_unit && invoiceItem.warehouse_units.units[item.unitId].storage_unit !== '') {
            //   newProduct.name = invoiceItem.warehouse_units.units[item.unitId].storage_unit
            // } else {
            //   newProduct.name = invoiceItem.name
            // }

            var stUnit = invoiceItem.warehouse_units.units[item.unitId].storage_unit
            if (stUnit === '.kom') {
              stUnit = ''
            }
            if (invoiceItem.warehouse_units.units[item.unitId].base_unit === true) {
              stUnit = invoiceItem.selling_unit
            }
            newProduct.name = invoiceItem.name + ' ' + stUnit
            newProduct.id = newProduct.id + '.' + item.unitId
            invoiceItem = newProduct
          }
        }

        invoiceItem.menu = [
          { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
          { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' },
          { text: 'Dodaj napomenu', method: 'addNoteArticleDialogOpen' }
        ]
        invoiceItem.total = 0

        if (this.cashReg.type === 'VP') {
          invoiceItem = this.getWholesaleItem(invoiceItem)
        }

        const existingInvoiceItem = this.invoice.items.find(p =>
          p.id === invoiceItem.id
        )
        if (existingInvoiceItem) {
          const quantity = existingInvoiceItem.quantity + 1
          existingInvoiceItem.quantity = quantity
          existingInvoiceItem.unitPrice = existingInvoiceItem.prices[this.currency].price
          existingInvoiceItem.discountedUnitPrice = existingInvoiceItem.unitPrice
          existingInvoiceItem.total = existingInvoiceItem.unitPrice * quantity
        } else {
          if (invoiceItem.manual_price_input) {
            invoiceItem.currency = this.currency
            this.$refs.addManualPrice.open(invoiceItem)
            return
          }
          invoiceItem.quantity = 1
          invoiceItem.unitPrice = invoiceItem.prices[this.currency].price
          invoiceItem.discountedUnitPrice = invoiceItem.unitPrice
          invoiceItem.total = invoiceItem.unitPrice
          this.invoice.items.unshift(invoiceItem)
          const rbr = this.invoice.items.findIndex(p => p.id === item.id) + 1
          this.invoice.items.find(p => p.id === item.id).rbr = rbr
        }
      }

      this.recalculate()
    },
    transactionEndedWithReceipt () {
      if (this.cashReg.after_receipt_behaviour !== undefined && this.cashReg.after_receipt_behaviour !== null && this.cashReg.after_receipt_behaviour !== '') {
        if (this.cashReg.after_receipt_behaviour === 'SHOW_CASH_REGS') {
          if (this.cashReg.login_type === 'SELECT_USER' || this.cashReg.login_type === 'PINCODE') {
            state.setCashRegisterUser({ id: undefined })
            df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
          }
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        } else if (this.cashReg.after_receipt_behaviour === 'SHOW_ALL_RECEIPTS') {
          if (this.cashReg.login_type === 'SELECT_USER' || this.cashReg.login_type === 'PINCODE') {
            state.setCashRegisterUser({ id: undefined })
            df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
          }

          // Potreban timeout jer kasa izbaci kad odjavim korisnika pa ostane na blagajni
          setTimeout(() => {
            this.$router.push({
              name: 'pointOfSale.receipts',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id
              }
            })
          }, 500)
        }
      }
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    addDiscount (mainBasket = false, itemID) {
      this.$refs.addDiscountForm.open(mainBasket, itemID, this.currency)
    },
    addNoteDialogOpen () {
      this.$refs.addNoteDialog.open(this.selectedTable)
    },
    addNoteArticleDialogOpen (item) {
      this.$refs.addNoteArticleDialog.open(item, this.invoice, this.selectedTable)
    },
    onAddDiscount (discount) {
      if (discount !== undefined) {
        if (discount.basketDiscount === true) {
          switch (discount.type) {
            case 'RATE': {
              const temp = ((this.invoice.total) * (discount.rate / 10000))
              this.invoice.discount = discount
              this.invoice.discount.amount = temp
              break
            }
            case 'AMOUNT':
              this.invoice.discount = discount
              this.invoice.discount.rate = (discount.amount / this.invoice.total) * 100
              break
          }
        } else {
          this.invoice.items.forEach((item, key) => {
            if (item.id === discount.itemID) {
              this.invoice.items[key].discount = discount

              switch (discount.type) {
                case 'RATE': {
                  const temp = Math.round((this.invoice.items[key].prices[this.currency].price) - ((this.invoice.items[key].prices[this.currency].price * (discount.rate / 10000))))
                  this.invoice.items[key].discounted_price = temp
                  this.invoice.items[key].discount.amount = this.invoice.items[key].prices[this.currency].price - this.invoice.items[key].discounted_price
                  this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].discounted_price

                  if (this.invoice.items[key].discounted_price <= 0) {
                    this.invoice.items[key].discount = undefined
                    this.invoice.items[key].discounted_price = 0

                    this.showMsgBox({ text: 'Popust je veći od cijene artikla, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  }
                  break
                }
                case 'AMOUNT':
                  this.invoice.items[key].discounted_price = this.invoice.items[key].prices[this.currency].price - discount.amount
                  this.invoice.items[key].discount.rate = ((discount.amount / this.invoice.items[key].prices[this.currency].price) * 100) * 100
                  this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].discounted_price
                  if (this.invoice.items[key].discounted_price <= 0) {
                    this.invoice.items[key].discount = undefined
                    this.invoice.items[key].discounted_price = 0
                    this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].discounted_price
                    this.showMsgBox({ text: 'Popust je veći od cijene artikla, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  }
                  break
              }

              this.invoice.items[key].menu = [
                { text: 'Ukloni popust', method: 'removeDiscount', icon: 'mdi-delete' },
                { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' }
              ]
            }
          })
        }
        this.recalculate()
      }
    },
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')

      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    recalculate () {
      if (this.invoice.items.length > 0) {
        this.invoice.total = 0
        this.invoice.fullTotal = 0
        this.invoice.totalWithDiscount = 0
        this.invoice.totalShoppingCart = 0
        // let dodatna = 0

        this.invoice.items.forEach((item, key) => {
          const quantityString = String(this.invoice.items[key].quantity)
          let quantity = parseFloat(quantityString.replace(',', '.'))
          if (!quantity) {
            this.invoice.items[key].quantity = 1
            quantity = 1
          }
          this.invoice.items[key].total = this.invoice.items[key].unitPrice * quantity
          this.invoice.items[key].fullTotal = this.invoice.items[key].total

          if (this.invoice.items[key].discountedUnitPrice !== null && this.invoice.items[key].discountedUnitPrice !== undefined && this.invoice.items[key].discountedUnitPrice > 0) {
            this.invoice.items[key].fullTotal = this.invoice.items[key].total

            this.invoice.items[key].total = this.invoice.items[key].discountedUnitPrice * quantity
          }
          this.invoice.totalWithDiscount += this.invoice.items[key].total
          this.invoice.total += this.invoice.items[key].total

          this.invoice.fullTotal += this.invoice.items[key].fullTotal
          this.invoice.totalShoppingCart += this.invoice.items[key].total
        })

        if (this.invoice.discount !== undefined) {
          if (this.invoice.discount.type === 'RATE') {
            var temp = Math.round(((this.invoice.totalShoppingCart) * (this.invoice.discount.rate / 10000)))

            this.invoice.discount.amount = temp
            this.invoice.total = this.invoice.total - temp
            this.invoice.totalWithDiscount = this.invoice.total
            // dodatna = this.invoice.totalShoppingCart - temp
          } else {
            if (this.invoice.discount.amount > this.invoice.totalShoppingCart) {
              this.invoice.discount.rate = 1000
              this.invoice.discount.amount = this.invoice.totalShoppingCart
            } else {
              const temp = (this.invoice.totalShoppingCart) / (this.invoice.discount.amount)
              this.invoice.discount.rate = Math.round(temp * 100)
              this.invoice.total = this.invoice.totalShoppingCart - this.invoice.discount.amount
              this.invoice.totalWithDiscount = this.invoice.totalShoppingCart - this.invoice.discount.amount
              // dodatna = this.invoice.totalShoppingCart - this.invoice.discount.amount
            }
          }
        }

        // roundanje na 2 decimale
        if (this.invoice.total <= 125) {
          var skini = 0
          if (this.invoice.discount !== undefined) {
            skini = this.invoice.discount.amount
            this.$refs.showError.open('Uklonjen je popust na košaricu jer prelazi iznos ukupnog računa, ukoliko želite možete dodati popust na košaricu.')
          }
          this.invoice.discount = undefined
          this.invoice.totalWithDiscount = 0
          this.invoice.total += skini
        }

        this.$forceUpdate()
      }
      if (!this.invoice.items || this.invoice.items.length === 0) {
        this.invoice.total = 0
        this.invoice.fullTotal = 0
        this.invoice.totalWithDiscount = 0
        this.invoice.totalShoppingCart = 0
      }
    },
    removeDiscount (basketDiscount = false, itemID) {
      if (basketDiscount) {
        this.invoice.totalWithDiscount = 0
        this.invoice.discount = undefined
      } else {
        this.invoice.items.forEach((item, key) => {
          if (item.id === itemID) {
            this.invoice.items[key].discounted_price = this.invoice.items[key].unitPrice
            this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].unitPrice

            this.invoice.items[key].menu = [
              { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
              { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' }
            ]
          }
        })
      }
      this.recalculate()
    },
    onClearInvoice (table) {
      this.invoice.items = [...table.items]
    },
    async setRegisterPaymentPanel () {
      state.resetRegisterPaymentPanel()
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (register && register.data()) {
        const doc = register.data()
        if (doc && doc.register_payment_panel) {
          state.setRegisterPaymentPanel(doc.register_payment_panel)
        } else {
          state.setRegisterPaymentPanel('default')
        }
      } else {
        state.setRegisterPaymentPanel('default')
      }
      this.registerPaymentPanel = state.getRegisterPaymentPanel()
    },
    clearSelection () {
      this.selectedTable = new Table()
    },
    getWholesaleItem (item) {
      const newItem = clone(item)
      if (this.cashReg.type === 'VP') {
        var pnpAmt = 0
        if (newItem.prices[this.currency].taxes.length > 0) {
          newItem.prices[this.currency].taxes.forEach(tax => {
            if (tax.name === 'PNP') {
              pnpAmt = tax.rate
            }
          })
        }

        var taxes = newItem.prices[this.currency].taxes[0].rate + pnpAmt

        newItem.prices[this.currency].price =
          Math.round(parseFloat(newItem.prices[this.currency].price / (1 + taxes / 10000)), 2)
      }
      return newItem
    },

    calculateHeights () {
      let elementsTotalHeight = 0
      let tilesTotalHeight = 0
      const rowHeight = 58
      const headerHeight = 64
      const spacerHeight = 26
      const tableHeaderHeight = 54
      const footerHeight = 64

      tilesTotalHeight = 4 * rowHeight
      elementsTotalHeight = tilesTotalHeight + headerHeight + spacerHeight + tableHeaderHeight + footerHeight

      this.tableHeight = window.innerHeight - elementsTotalHeight
    }
  },
  created () {
    this.selectedTable = new Table()

    this.invoice = {
      currency: this.currency,
      // discount: {},
      fullTotal: 0,
      items: [],
      paymentConfig: {},
      registerType: 'MP',
      title: '',
      total: 0,
      totalShoppingCart: 0,
      totalWithDiscount: 0,
      type: 'R'
    }

    this.cashReg = state.getCashRegister()
    window.addEventListener('resize', this.calculateHeights)
  },
  async mounted () {
    this.setRegisterPaymentPanel()

    EventBus.$off('showError')
    EventBus.$on('showError', (data) => {
      this.$refs.showError.open(data.message)
    })

    EventBus.$off('change-register-payment-panel')
    EventBus.$on('change-register-payment-panel', () => {
      this.registerPaymentPanel = state.getRegisterPaymentPanel()
    })

    EventBus.$off('clear-reload-tables')
    EventBus.$on('clear-reload-tables', () => {
      this.onClearTable()
    })

    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    this.calculateHeights()

    this.setup()

    EventBus.$off('clear-receipt-data')
    EventBus.$on('clear-receipt-data', () => {
      EventBus.$emit('clear-table')
      // this.generalMenu = [
      //   { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
      //   { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' }
      // ]
      this.invoice.items = []
      this.selectedTable = new Table()
      this.transactionEndedWithReceipt()
    })

    // EventBus.$off('set-mode')
    EventBus.$on('set-mode', (editMode) => {
      this.editMode = editMode
    })

    EventBus.$off('set-orders-view')
    EventBus.$on('set-orders-view', (ordersView) => {
      this.ordersView = ordersView
    })
    document.onkeydown = this.handleKeyEvents
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
    window.removeEventListener('resize', this.calculateHeights)
  }

}
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.text-wrap {
  width: 100%;
  margin: auto;
}

.panel-container {
  padding: 0;
  margin: 0;
}
.panel-row {
  margin: 0;
  padding: 0;
}
.panel-cell {
  padding: 0;
  border: 1px solid lightgray;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.disabled {
  background-color: var(--v-primary-base);
}
.label {
  color: white;
}

.invoice-row {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

.total-row {
  padding: 1rem 1rem 0 0;
  width: 58vw;
  height: 4rem;
  color: white;
  background-color: #2c5a90;
  position: fixed;
  bottom: 0;
  border-radius: 0 10px 0 0;
  text-align: right;
}

.invoice-table-wrapper {
  border: 1px solid darkgrey;
}

@media only screen and (max-height: 899px) {
  .invoice-table-wrapper {
    height: 350px;
    overflow-y: auto;
  }
  .label {
    font-size: 0.9rem;
    font-weight: 700;
  }
}

@media only screen and (min-height: 900px) {
  .invoice-table-wrapper {
    height: 530px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .label {
    font-size: 0.9rem;
    font-weight: 700;
  }
}
</style>
